import React, { useState } from 'react';
import menu from '../../assets/icons/menu.svg';
import close from '../../assets/icons/close.svg';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/SVG/logo.svg';
import './Header.scss';

const Header = () => {
    const [open, setOpen] = useState(false);
    // const dropDownRef = useRef()

    const toggle = () => {
        setOpen(!open)
    }

    // const closeMenu = () => {
    //     setOpen(false);
    // }
    return (
        <>  
            {/* {open && <div onClick={closeMenu} className="header__dropclose"/>} */}
            <header className="header">
                <Link className="header__logo"to="/"><img className="header__svg" src={logo} alt="deluxe time logo"/></Link>
                <nav className="header__nav">
                    <ul className="header__links">
                        <Link className="header__links-item" to="/services"><li>Services</li></Link>
                        <Link className="header__links-item" to="/about"><li>About Us</li></Link>
                        <Link className="header__links-item" to="/contact"><li>Contact</li></Link> 
                    </ul>
                    {open ? 
                    <img onClick={toggle}className="header__hbmenu"src={close} alt="Close Menu Icon"/> : 
                    <img onClick={toggle}className="header__hbmenu"src={menu} alt="Hamburger Menu Dropdown Icon"/> }
                </nav>
                
            </header>
            {open && 
            <div className="header__dropdown">
                <Link onClick={toggle} to="/services" className="header__nav-item">Services</Link>
                <Link onClick={toggle} to="/about" className="header__nav-item">About Us</Link>
                <Link onClick={toggle} to="/contact" className="header__nav-item">Contact</Link>
            </div>
            }
        </>
    )
}

export default Header
