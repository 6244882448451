import React from 'react';
// import Clock from '../../components/Clock/Clock';
import { motion } from 'framer-motion'
// import Hero from '../../components/Hero/Hero';
import InfoCard from '../../components/InfoCard/InfoCard';
// import service from '../../assets/images/SVG/service.svg'
import './Home.scss';


const servList = ["Battery Replacement", "Strap and bracelet adjustments", "Stem/Crown Services", "Glass replacement", "and more.."]
const brandList = ["Seiko", "Citizen", "Timex", "Skagen", "Anne Klein", "etc."]
const fadeIn = {
    hidden: {
      x: 1000,
      overflowX: "hidden",
    },
    visible:{
      x: 0,
      transition: {
        duration: 1.5
      }
    },
    exit: {
      opacity: 0
    }
  }
const Home = () => {
    return (
        <motion.div
        initial="hidden"
        animate="visible"
        exit={{x: -1500, transition: { duration: 1.5 }}}
        variants={fadeIn}>
            <main className="home">
                {/* <div className="home__clock">
                    <Clock />
                </div> */}
                
            <div className="home__wrapper">
                <InfoCard title="our story" content="Deluxe Time + specializes in providing excellent repairs and servicing for your most cherished timepieces." link="/about" btn="About us"/>
                <InfoCard title="services" list={servList} link="/services" btn="Learn more"/>  
            </div>
            <div className="home__wrapper">
                <InfoCard title="brands" align="left" list={brandList}/>
                <InfoCard title="contact" content="We value your opinions and concerns on your experience with us. If you have any questions please use the contact form provided!" link="/contact" btn="Contact us"/>
            </div>
            </main>
        </motion.div>
    )
}

export default Home
