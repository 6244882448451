import React from 'react';
import { Link } from 'react-router-dom';
import './InfoCard.scss';


const InfoCard = ({title, content, list, link, btn, align}) => {
    return (
        <div className="infocard">
            <h3 className="infocard__title">{title}</h3>
            {content && <p className="infocard__content">{content}</p>}
            {list && 
            <ul className="infocard__list">
            {list.map((item) => {
                return (
                    <li style={{textAlign: `${align}`}}className="infocard__list-item">{item}</li>
                )
            })}
            </ul>}
            {link && <Link className="infocard__link" to={link}>
                <span>{btn}</span>
                <span className="infocard__link-arrow">→</span>
            </Link>}
        </div>
    )
}

export default InfoCard
