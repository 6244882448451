import React from 'react';
import { motion } from 'framer-motion'
import './About.scss';

const About = () => {
    return (
        <motion.div
        initial={{opacity: 0}}
        animate={{opacity: 1}} 
        exit={{opacity: 0}}
        className="about">
            <h2 className="about__title">About Us</h2>
            
        </motion.div>
    )
}

export default About
