import React, { useState } from 'react';
import { motion } from 'framer-motion';
import axios from 'axios'
import './Contact.scss';
import InputLabel from '../../components/InputLabel/InputLabel';
import TextArea from '../../components/TextArea/TextArea';
import useContactForm from '../../customHooks/useContactForm'
import ThankYou from './ThankYou';



const Contact = () => {
    const [thankYou, setThankYou] = useState(false)
    

    const {inputs, errors, handleInputChange, handleSubmit, clearInputs} = useContactForm(() => {
        sendMessage(inputs)
        clearInputs();
    });

    const sendMessage = (msg) => {
        console.log(msg)
        axios.post(`https://${process.env.REACT_APP_API_URL}/contact`, msg)
        .then(res => {
            console.log(res)
            setThankYou(true);
        })
        .catch(err => {
            console.log(err)
        })
    }
    return (
        <motion.div 
        initial={{x: 1500, overflowX: "hidden"}}
        animate={{x: 0, transition: { duration: 2 }}}
        exit={{x: -1500, transition: { duration: 1.5 }}}
        className="contact">
            <h2 className="contact__title">Contact Us</h2>
            {!thankYou && 
            <>
                <p className="contact__message">Any questions, suggestions, compliments or concerns, feel free to leave your message here!</p>
                    <form className="form" onSubmit={handleSubmit}>
                        <div className="form__wrapper">
                            <InputLabel label="First Name" handleChange={handleInputChange} value={inputs.first} name="first" error={errors.first}/>
                            
                            <InputLabel label="Last Name" handleChange={handleInputChange} value={inputs.last} name="last" error={errors.last}/>
                            
                        </div>
                        <InputLabel label="E-mail" type="email" handleChange={handleInputChange} value={inputs.email} name="email" error={errors.email}/>
                        <TextArea label="Message" name="message" handleChange={handleInputChange} value={inputs.message} error={errors.message}/>
                        <button className="contact__button">Send</button>
                    </form> 
            </>
            }  

            {thankYou && <ThankYou />}
        </motion.div>
    )
}

export default Contact
