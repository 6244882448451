import React from 'react'
import './Services.scss';
import { motion } from 'framer-motion';
import ServiceCard from '../../components/ServiceCard/ServiceCard';

const Services = () => {
    return (
        <motion.div
        className="services"
        initial={{x: 1000, overflowX: "hidden"}}
        animate={{x: 0, transition: { duration: 1.5 }}}
        exit={{x: -1500, transition: { duration: 1.5 }}}>
            
            <h3 className="services__title">Services</h3>
            
            <div className="services__wrapper">
               <ServiceCard 
               title="Battery Replacement" 
               desc="Our on-site battery replacement services are all done with Japanese made SEIZAIKEN batteries. This service comes with a 1 year warranty for the battery."/> 
               <ServiceCard 
               title="Strap and bracelet adjustment/replacement" 
               desc="We have a variety of selections for your watch strap. (leather, stainless steel, etc.) We can also make adjustments to your current strap/bracelet to size it to your wrist."/> 
               <ServiceCard 
               title="Stem and Crown replacement" 
               desc="If your crown is damaged or lost we provide replacement services. If needed we are also able to find your original manufacturer's crown for the replacement."/> 
               <ServiceCard 
               title="Glass Replacement" 
               desc="If the face of your watch is damaged we are able to repair that for you. We can replace Sapphire, Mineral and Acrylic glass and properly reseal if applicable."/> 
               <ServiceCard 
               title="and more.." 
               desc="We offer a wide variety of watch and clock repair services. If you have questions on repairs that are not listed please use our contact page and we will provide you with more information."/> 
            </div>
        </motion.div>
    )
}

export default Services
