import React from 'react';
import './ServiceCard.scss';

const ServiceCard = ({ title, desc }) => {
    return (
        <div className="scard">
            <h4 className="scard__title">{title}</h4>
            <p className="scard__desc">{desc}</p>
        </div>
    )
}

export default ServiceCard
