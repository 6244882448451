import { useState } from 'react'

const validateInput = (values) => {
    let errors ={};
    if (!values.email) {
        errors.email = "Email address is required";
    }

    if(!values.first) {
        errors.first = "First name is required";
    }

    if(!values.last) {
        errors.last = "Last name is required";
    }

    if (!values.message) {
        errors.message = "Message is required";
    }
    return errors;
}



const useContactForm = (callback) => {
    const [inputs, setInputs] = useState({
        first:"",
        last:"", 
        email:"", 
        message:""
    })
    const [errors, setErrors] = useState({});


    const handleSubmit = (e) => {
        if(e){
            e.preventDefault();
            setErrors(validateInput(inputs));
        }
        callback()
    }

    const clearInputs = () => {
        setInputs({
            first: "",
            last: "",
            email: "",
            message: ""
        })
    }
    const handleInputChange = (e) => {
        e.persist();
        setInputs(inputs => ({...inputs, [e.target.name]:e.target.value}))
        setErrors(errors => ({...errors, [e.target.name]:""}));
        
    }
    return {
        handleSubmit,
        handleInputChange,
        inputs,
        errors,
        clearInputs
    };
}

export default useContactForm
