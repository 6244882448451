import React from 'react';
import './Contact.scss';
const ThankYou = () => {
    return (
        <div className="thanks">
            <h3 className="thanks__title">Thank you!</h3>
            <p className="thanks__msg">We value your opinion and we really appreciate your time to leave us a message!</p>
            <p className="thanks-sign">- Deluxe Time</p>
        </div>
    )
}

export default ThankYou
