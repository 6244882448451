import { Switch, Route, useLocation } from 'react-router-dom'
import Header from './components/Header/Header';
import Home from './pages/Home/Home';
import Contact from './pages/Contact/Contact';
import About from './pages/About/About';
import { AnimatePresence } from 'framer-motion';
import './App.scss';
import Footer from './components/Footer/Footer';
import Services from './pages/Services/Services';



function App() {
  const location = useLocation();

  return (
    <>
       <Header />
       <AnimatePresence exitBeforeEnter>
        <Switch location={location} key={location.pathname}>
            <Route path="/" exact component={Home}/>
            <Route path="/about" component={About}/>
            <Route path="/contact" component={Contact}/>
            <Route path="/services" component={Services}/>
        </Switch> 
      </AnimatePresence>

      <Footer />
    </>
  );  
}

export default App;
