import React from 'react'
import './InputLabel.scss';
import { motion } from 'framer-motion';

const InputLabel = ({label, type, handleChange, value, name, error}) => {
    return (
        <div className="input__wrapper">
            <label className="input__label" htmlFor="">{label}</label>
            <input 
            onChange={handleChange} 
            className="input__input" 
            type={(type) ? type : "text"}
            value={value}
            name={name}/>
            {error && <motion.p 
            initial={{x: "100%"}}
            animate={{x: 0}}
            className="input__error">{error}</motion.p>}
        </div>
    )
}

export default InputLabel
