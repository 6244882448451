import React from 'react';
import './Footer.scss';


const Footer = () => {
    return (
        <footer className="footer">
            <h4 className="footer__title">DELUXE TIME +</h4>
            <div className="footer__wrapper">
                <div className="footer__section">
                    <h3 className="footer__subtitle">Address</h3>
                    <p>595 Bay St</p>
                    <p>Toronto, ON, <strong>M5G2C2</strong></p>
                </div>
                <div className="footer__section">
                    <h3 className="footer__subtitle">Contact</h3>
                    <p className="footer__email">Email: <a href="mailto:deluxetimetoronto@gmail.com">deluxetimetoronto@gmail.com</a></p>
                    <p>Phone: +1 (416) 595-5635</p>
                </div>
                <div className="footer__section">
                    <h3 className="footer__subtitle">Hours</h3>
                    <p>Mon - Fri: 12pm - 5pm</p>
                    <p><strong>Sat - Sun:</strong> by appointment</p>
                </div>
            </div>
            <p className="footer__hang">DELUXE TIME + | 2021 © </p>
        </footer>
    )
}
export default Footer
