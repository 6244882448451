import React from 'react';
import './TextArea.scss';
import { motion } from 'framer-motion';

const TextArea = ({label, name, value, handleChange, error}) => {
    return (
        <div className="textarea">
            <label className="textarea__label" htmlFor={label}>{label}</label>
            <textarea 
            className="textarea__input" 
            name={name} id={name} 
            value={value}
            cols="30" 
            rows="10"
            onChange={handleChange}></textarea>
            {error && <motion.p 
            initial={{x: "100%"}}
            animate={{x: 0}}
            className="textarea__error">{error}</motion.p>}
        </div>
    )
}

export default TextArea
